<template>
    <div class="text-center" style="padding-top: 40px;">
        <p><van-icon name="success" size="60" color="#07c160" /></p>
        <h3 style="color: #07c160;margin-bottom: 30px;">奖品领取成功！</h3>
        <h5 style="margin-bottom: 20px;padding: 0 10px;font-size: 15px;">温馨提示：</h5>
        <p style="margin: 0 0 10px;padding: 0 10px;font-size: 15px;font-weight: bold;">请在我的订单中查看奖品信息，</p>
        <p style="margin: 10px 0 0;padding: 0 10px;font-size: 15px;font-weight: bold;">并与在线客服确认返现方法</p>
        <div style="padding: 50px;">
            <van-button round style="height: 40px;font-size: 14px;padding: 0 50px;" size="large" type="danger"
                        @click="$router.replace('/lottery/mine?active=2')">查看我的订单</van-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LotteryConfirmSuccess",
    }
</script>

<style scoped>
    .text-center{
        text-align: center;
    }
</style>